import { Box, Container, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MoneyPig from "../../../../../assets/images/money-pig.png";
import LoadingButton from "../../../../generic-components/button";
import { TRACKING_HOME_OPTIONS } from "../../../../../constants";
import NoPlanDialog from "./NoPlanDialog";
import useStepper from "../../../../../hooks/useStepper";
import usePlan from "../../../../../hooks/usePlan";
import { useNavigate } from "react-router-dom";
import isEmpty from "lodash.isempty";
import { legacyAppRedirection } from "../../../../../helpers";
import HomePageApplyButtonModal from "../../shared-cost-application/modal/HomePageApplyButtonModal";

const HomeDescriptionContent = ({ organisation, user }) => {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { personalDetails } = useStepper();
  const { isLatestActivePlan } = usePlan();
  const navigate = useNavigate();

  const handleRedirectionToApplication = () => {
    if (organisation?.new_form) {
      navigate("/new-application-form");
      return;
    }
    legacyAppRedirection(
      personalDetails?.sub_domain,
      `/users/${user.id}/new_scavc_application`
    );
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    if (
      !isEmpty(personalDetails) &&
      !personalDetails?.latest_plan &&
      !personalDetails?.latest_temp_plan &&
      !isLatestActivePlan
    ) {
      setOpen(true);
    }
  }, [personalDetails]);

  return (
    <Container>
      <Stack
        direction={{ lg: "row", md: "row", sm: "column" }}
        justifyContent="space-between"
      >
        <Stack direction="column">
          <Box className="shrink-box">
            <Typography className="mt-30 home-typography">
              <strong>{organisation?.name}</strong> has arranged for you to
              benefit from a{" "}
              <span className="primary-text">
                Shared Cost Additional Voluntary Contribution (AVC).
              </span>
            </Typography>

            <Typography className="mt-30 home-typography">
              At <strong> My Money Matters</strong>, we’re experts in helping
              you to make sense of your money. We’ll guide you through the
              application and what it means, to make it easy.
            </Typography>
            <Stack direction="row" className="mt-18">
              <LoadingButton
                buttonTitle="Apply Now"
                trackingDetails={TRACKING_HOME_OPTIONS}
                handleClick={() => setOpenModal(true)}
                styleClass={
                  "btn primary-clr-btn apply-now-btn thinbtn mt-12 text-bold"
                }
              />
            </Stack>
            <Typography className="mt-30 home-typography">
              <span className="primary-text">
                {" "}
                Shared Cost AVC is certainly a mouthful.{" "}
              </span>
              Let’s break down the jargon.
            </Typography>
          </Box>
        </Stack>
        <Box className="mt-5 saving-jars-container">
          <Box
            component="img"
            alt="money-pig"
            className="money-pig"
            src={MoneyPig}
          />
        </Box>
      </Stack>
      <NoPlanDialog
        open={open}
        onClose={handleClose}
        scrollToApply={handleRedirectionToApplication}
      />
      <Stack
        direction={{ lg: "row", md: "row", sm: "column" }}
        spacing={{ lg: 3, md: 3, sm: 2 }}
        justifyContent="space-between"
        className="mt-50 purple-text custom-spacing-sm"
      >
        <Box className="home-page-box">
          <Typography variant="h6" className="purple-text text-bold-lg">
            Shared Cost
          </Typography>
          <Typography variant="body1 home-typography">
            Both you and your employer can add money into it
          </Typography>
        </Box>
        <Box className="home-page-box">
          <Typography variant="h6" className="purple-text home-typography">
            <span className="text-bold-lg">A</span>dditional
          </Typography>
          <Typography variant="body1 home-typography">
            It’s a separate pot you pay into, on top of the local government
            pension you already have
          </Typography>
        </Box>
        <Box className="home-page-box">
          <Typography variant="h6" className="purple-text home-typography">
            <span className="text-bold-lg">V</span>oluntary
          </Typography>
          <Typography variant="body1 home-typography">
            It’s your choice to join, and you can choose how much to pay in
          </Typography>
        </Box>
        <Box className="home-page-box">
          <Typography variant="h6" className="purple-text home-typography">
            <span className="text-bold-lg">C</span>ontribution
          </Typography>
          <Typography variant="body1 home-typography">
            This is the amount you pay into this extra pot every payroll
          </Typography>
        </Box>
      </Stack>
      <HomePageApplyButtonModal open={openModal} setOpen={setOpenModal} />
    </Container>
  );
};

const mapStateToProps = (state) => {
  return {
    organisation: state?.organisation?.organisation,
    user: state?.user?.user,
  };
};

HomeDescriptionContent.propTypes = {
  organisation: PropTypes.object,
  user: PropTypes.object,
};

export default connect(mapStateToProps)(HomeDescriptionContent);
